<template>
  <div>
    <!-- 播放器容器 -->
    <div ref="playerContain" class="player-container"></div>
  </div>
</template>

<script>
import {PuPlayer} from "@besovideo/webrtc-player";
import "@besovideo/webrtc-player/dist/main.es.css";

export default {
  name: "vedio",
  data() {
    return {
      token: "",
      r: "",
      index: 1,
      videoFit: "contain",
      instance: null,
      messages:[],
    };
  },
  cerated() {

  },
  mounted() {
    this.r = this.$route.query.id;
    this.index = Number(this.$route.query.index);
    this.login();
  },
  methods: {
    // 登录
    async login() {
      try {
        const r = await fetch("/bvcsp/v1/auth/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: "admin",
            password: "123456",
          }),
        });
        if (r.ok || (r.status >= 200 && r.status < 300)) {
          const res = await r.json();
          // 设置token
          this.token = res.data?.token;
          this.$nextTick(() =>{
            this.init();
          })
          this.setCookie("Authorization", this.token, res.data?.timeout);
          return;
        }
      } catch (e) {
        console.error(e);
      }
    },

    // 初始化播放器
    init() {
      if (!this.token) return;
      const {instance} = PuPlayer({
        // (可选) 容器节点 注意一个容器内只能存在一个实例  当container为假值（包括false、null、undefined）时 将返回实例引用的dom节点 容器必须指定高度 参考高德地图
        container: this.$refs.playerContain,
        // 必填 设备选项
        puOption: {
          // 设备id
          id: this.r,
          // 设备通道号
          index: this.index,
        },
        // 必填 用户授权令牌
        token: this.token,
        videoFit: this.videoFit,
        // (可选) 双击是否全屏
        fullScreenOnDblclick: true,
        type: "webrtc" | "ws-bvrtc" | "auto",
        // (可选) 启用控制器
        enableController: true,
        onConnected: () => {
          this.messages.push("onConnected 连接已建立");
        },
        onConnectedFailed: () => {
          this.messages.push("onConnectedFailed 连接建立失败");
        },
        onClose: () => {
          this.messages.push("onClose 连接已关闭（播放器关闭）");
        },
        onDisConnected: () => {
          this.messages.push("onDisConnected  连接已关闭（服务器断开连接）");
        },
        onDestroy: () => {
          this.messages.push("onDestroy 播放器实例已销毁");
        },
      });

      this.instance = instance;

      // 打开连接
      this.$nextTick(() =>{
        instance.open();
      })

    },

  },
};
</script>

<style scoped lang="less">
.player-container {
  height: 80vh;
  width: 90vw;
  margin: auto;
  border: 1px solid #ddd;
}
</style>
